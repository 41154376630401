/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink, Box, useThemeUI } from "theme-ui";
import { yirOrange } from "../gatsby-theme-minimal-blog/gatsby-plugin-theme-ui";

const Button = ({ ...props }) => {
  const { theme } = useThemeUI();
  return (
    <button
      sx={{
        border: "none",
        WebkitAppearance: "none",
        padding: "12px 16px",
        borderRadius: "2px",
        outlineColor: yirOrange,
        mx: "4px",
        backgroundColor: theme.colors.primary,
        color: "#FFF"
      }}
      {...props}
    />
  );
};

export default Button;
