/** @jsx jsx */
import React, { useRef, useState } from "react";
import { jsx, Link as TLink, Box } from "theme-ui";
import Button from "../../yir-ui/Button";
import TextInput from "../../yir-ui/TextInput";
import { useLocation } from "@reach/router";
import { yirOrange } from "../gatsby-plugin-theme-ui";

const EmailForm = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const location = useLocation();
  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(form.current);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      // @ts-ignore
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => alert(error));
  };
  if (submitted) {
    return <div sx={{ color: yirOrange }}>Welcome to the club!</div>;
  }
  return (
    <form
      ref={form}
      name="email-subscribe"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <p>
        <input type="hidden" name="form-name" value="email-subscribe" />
        <input type="hidden" name="bot-field" />
        <TextInput label="Email Address" type="email" name="email" />
        <input type="hidden" name="source" value={location.pathname} />
        <Button type="submit">Subscribe</Button>
      </p>
    </form>
  );
};

export default EmailForm;
