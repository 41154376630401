/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink, Box } from "theme-ui";
import { yirOrange } from "../gatsby-theme-minimal-blog/gatsby-plugin-theme-ui";

const TextInput = ({ label, ...props }) => {
  return (
    <input
      sx={{
        border: "none",
        WebkitAppearance: "none",
        padding: "12px 8px",
        width: 240,
        borderRadius: "2px",
        outlineColor: yirOrange
      }}
      aria-label={label}
      placeholder={label}
      {...props}
    />
  );
};

export default TextInput;
