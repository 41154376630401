/** @jsx jsx */
import * as React from "react";
import { jsx, Link as TLink } from "theme-ui";

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
};
const describeArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    arcSweep,
    0,
    end.x,
    end.y
  ].join(" ");

  return d;
};
const generateSVG = (size, runScore, time, textColor = "#FFF") => {
  return (
    <div className="arc" style={{ height: size * 2 + "px" }}>
      {time ? (
        <div style={{ fontSize: size * 0.65, textAlign: "center" }}>{time}</div>
      ) : (
        ``
      )}
      <svg height={size * 2} width={size * 2}>
        <path
          d={describeArc(
            size,
            size,
            size - 5,
            180,
            180 + 359.9 * (runScore / 10)
          )}
          fill="none"
          stroke={runScore >= 8 ? "#0F0" : runScore >= 5 ? "#FF0" : "#F00"}
          strokeWidth={size / 8}
        />
        <text
          fill={textColor}
          color={textColor}
          stroke="none"
          fontSize={size - 5}
          fontWeight="normal"
          x={size}
          y={size * 1.25}
          textAnchor="middle"
        >
          {runScore}
        </text>
      </svg>
    </div>
  );
};

const RunScoreArc = ({ epoch, score = 2.5, baseSize = 0.75, size = 20 }) => {
  return generateSVG(
    baseSize * size,
    Math.max(0, Math.min(10, Math.round(score * 2))),
    epoch &&
      new Date(epoch).toLocaleTimeString("en-US", {
        hour: "numeric"
        //timeZone: this.timezone
      })
  );
};

export default RunScoreArc;
