/** @jsx jsx */
import * as React from "react";
import { jsx, Heading, Spinner } from "theme-ui";
import EmailForm from "../../src/@lekoarts/gatsby-theme-minimal-blog/components/email-form";
import RunScoreArc from "../../src/@lekoarts/yir-ui/RunScoreArc";
import * as Icons from "./08-11-2021-falmouth-road-race-weather/icons";
//import sampleWeather from "./sampleWeather";

const getIcon = (iconName) => {
  switch (iconName) {
    case "snow":
      return <Icons.Snow />;
    case "rain":
      return <Icons.Rain />;
    case "fog":
      return <Icons.Cloudy2 />;
    case "wind":
      return <Icons.Cloudy2 />;
    case "cloudy":
      return <Icons.Cloudy />;
    case "partly-cloudy-day":
      return <Icons.PartlyCloudyDay />;
    case "partly-cloudy-night":
      return <Icons.PartlyCloudyNight />;
    case "clear-day":
      return <Icons.ClearDay />;
    case "clear-night":
      return <Icons.ClearNight />;
    default:
      return <Icons.Sunny />;
  }
};

export default ({ race }) => {
  const [weather, setWeather] = React.useState();
  const [error, setError] = React.useState(false);
  const [suspense, setSuspense] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setSuspense(false);
    }, 250);
    if (!weather) {
      fetch(
        `https://us-central1-running-weather.cloudfunctions.net/${race}2021`
      )
        .then((data) => data.json())
        .then((data) => setWeather(data))
        .catch((error) => {
          setError(true);
        });
    }
  }, []);

  if (suspense) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px",
          height: 200
        }}
      >
        <div> </div>
        <div> </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px"
        }}
      >
        <div>This event has concluded.</div>
      </div>
    );
  }

  if (!weather) {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px"
        }}
      >
        <div>
          <Spinner />
        </div>
        <div>Whipping up that forecast!</div>
      </div>
    );
  }

  return (
    <div>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          px: [0, "12px", "24px"]
        }}
      >
        {weather.map((hour) => {
          const localeTime = new Date(
            hour.datetimeEpoch * 1000
          ).toLocaleTimeString();
          return (
            <div sx={{ fontSize: 1, padding: "12px 24px" }}>
              <div sx={{ fontSize: 2, color: "#AEAEAE", textAlign: "left" }}>
                {localeTime.split(":")[0]} {localeTime.split(" ")[1]}
              </div>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                {getIcon(hour.icon)}
                <div
                  sx={{
                    fontSize: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "-4px"
                  }}
                >
                  <div>{Math.round(hour.temp)}º</div>
                  <div>
                    <RunScoreArc size={20} score={hour.runScore} />
                  </div>
                </div>
              </div>
              <div
                sx={{ fontSize: "14px", textAlign: "left", paddingTop: "4px" }}
              >
                <div>Feels Like: {Math.round(hour.feelslike)}º</div>
                <div>Humidity: {hour.humidity}%</div>
                <div>Dew Point: {hour.dew}º</div>
                <div>Rain: {Math.round(hour.precipprob)}%</div>
                <div>WBGT: {hour.wbgt}º</div>
              </div>
            </div>
          );
        })}
      </div>

      <div class="citation" style={{ textAlign: "right", margtinTop: "4px" }}>
        Forecast Weather Data Provided by{" "}
        <a
          href="https://www.visualcrossing.com/weather-data"
          target="_blank"
          style={{ color: "inherit" }}
        >
          Visual Crossing
        </a>
        . RunScore and WBGT estimates provided by{" "}
        <a
          href="https://eveningrun.com"
          target="_blank"
          style={{ color: "inherit" }}
        >
          Evening Run
        </a>
        .
      </div>
    </div>
  );
};
