/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink } from "theme-ui";
import { Link } from "gatsby";
import imgYIR from "./yir-post-2020.jpg";
import imgCheermap from "./cheermap.jpg";
import imgAconcagua from "./aconcagua2.png";
import imgEveningRun from "./eveningrun.png";
import imgPaceCalculator from "./pace-calculator.png";
import imgChaskiChallenge from "./chaski-challenge.png";
import imgAthleticBrewingMarathonRelay from "./athletic-brewing-marathon-relay.png";
import imgWesternStates from "./western-states-fantasy.jpg";

function AsLink(props: any) {
  return <Link activeClassName="active" {...props} />;
}

const Work = ({ name, url = "", image, description }) => {
  const content = (
    <React.Fragment>
      <img src={image} />
      <div sx={{ px: "8px" }}>
        <h2 sx={{ my: 0, fontFamily: "Hahmlet", color: "#FFF" }}>{name}</h2>
      </div>
    </React.Fragment>
  );
  return (
    <div
      sx={{
        width: ["100%", "50%", "33%"],
        //textAlign: "center",
        padding: "8px",
        img: { width: "100%" },
        ":hover": {
          // borderLeft: "3px solid rgb(240, 85, 55)",
          // borderBottom: "1px solid rgb(240, 85, 55)",
          boxShadow: "6px 10px 0px rgb(240, 85, 55)",
          transform: "scale(1.015)"
        }
      }}
    >
      {url ? (
        <TLink as={AsLink} to={url}>
          {content}
        </TLink>
      ) : (
        content
      )}
      <div sx={{ px: "8px" }}>
        <p sx={{ textDecoration: "none", mt: 0 }}>{description}</p>
      </div>
    </div>
  );
};

const Works = () => {
  return (
    <div sx={{ display: "flex", flexWrap: "wrap" }}>
      <Work
        name="Full Senderismo Aconcagua"
        image={imgAconcagua}
        url={"/aconcagua"}
        description="In collaboration with the Chaski Endurance Collective, a live tracker for Tyler Andrews' world record attempt up Mt. Aconcagua, the highest mountain outside of Asia."
      />
      <Work
        name="Your Year in Running"
        image={imgYIR}
        url={"/2022"}
        description="The start of it all. This is our in-house year end visualization for your running stats. Going strong since 2017!"
      />
      <Work
        name="Pace Calculator"
        image={imgPaceCalculator}
        url={"/pace-calculator"}
        description="In collaboration with the Chaski Endurance Collective, we built a brandable, embeddable, pace calculator to scratch an itch."
      />
      <Work
        name="Evening Run"
        image={imgEveningRun}
        url={"https://eveningrun.com"}
        description="Evening Run is the weather app for runners. Countless hours of research and statistical analysis guided us in developing the RunScore® to best prepare you for your run."
      />
      <Work
        name="Western States Fantasy"
        image={imgWesternStates}
        description="Western States Fantasy was a quick sprint to develop a pick'em style fantasy draft for the 2021 Western States Endurance Run."
      />
      <Work
        name="cheermap.run"
        image={imgCheermap}
        url={"https://cheermap.run"}
        description="An instagram focused graphic to help uplift the fan experience at the Boston Marathon."
      />
      <Work
        name="Athletic Brewing Marathon Relay"
        image={imgAthleticBrewingMarathonRelay}
        url={"https://chaskichallenge.yearinrunning.com/marathon-relay"}
        description="Our team built a new live-tracking visualization for the second Chaski Challenege - A 16 team virtual marathon relay."
      />
      <Work
        name="Chaski Challenge"
        image={imgChaskiChallenge}
        url={"https://chaskichallenge.yearinrunning.com/"}
        description="We partnered with the Chaski Endurance Collective on a live-tracking project for their socially distant 2020 Chaski Challenge - an event where they set 8 treadmill world records!"
      />
    </div>
  );
};

export default Works;
